<template>

  <v-row >
    <v-col
        cols="4"
    >
      <v-layout>
        <v-flex>
          <v-card
              class="mx-auto"
          >
            <v-toolbar>
              <v-text-field
                  v-model="search"
                  @input="handleSearch"
                  label="Filter Selections"
                  flat
                  solo-inverted
                  hide-details
                  clearable
                  dense
                  clear-icon="mdi-close-circle-outline"
                  class="mr-1"
              ></v-text-field>
              <v-btn
                  text
                  outlined
                  @click="resetFilters"
                  class="pl-0 pr-0"
              >
                Reset
              </v-btn>

            </v-toolbar>
            <v-container grid-list-lg>
              <v-card-title>Pattern Types</v-card-title>
              <v-row>
                <v-treeview
                    ref="patternTree"
                    :items="patterns"
                    selection-type="leaf"
                    return-object
                    activatable
                    dense
                    hoverable
                    expand-icon="mdi-chevron-down"
                    :active="active"
                    :open.sync="patternsOpen"
                    @update:active="treeNodeActive"
                    @update:open="treeNodeOpen"
                    :search="search"
                >
                </v-treeview>
              </v-row>
            </v-container>
          </v-card>
          <v-btn
              class="ma-2"
              outlined
              color="indigo"
              @click="submitPatternNavigation()"
          >
            Submit Pattern
          </v-btn>
        </v-flex>
      </v-layout>
    </v-col>
    <v-col
        cols="12"
        md="8"
    >
      <v-skeleton-loader
          type="card, paragraph"
          elevation="2"
          loading
          v-if="loading"
      />
      <v-card
          v-if="patternMarkDown === null"
      >
        <v-card-text class="text--primary">
          This will show a brief intro of the patterns page when no pattern is selected
        </v-card-text>
      </v-card>
      <v-card v-if="patternMarkDown !== null"
              class="pa-3">
        <v-card-title class="text--primary" style="padding-left: 0px">
          {{ selectedPattern.name }}
        </v-card-title>
        <div v-html="md(patternMarkDown)"></div>
      </v-card>

      <v-divider

          class="mb-6"
      ></v-divider>
      <template
          v-if="authors !== null"
      >
        <v-card
            v-for="author in authors" :key="author.id"
            class="mx-auto mb-3 pa-2"
        >
          <v-row>
            <v-col
                cols="2"
            >
              <v-img
                  class="mt-3 mb-3 ml-3 mr-3 rounded-lg"
                  max-height="250"
                  :alt="author.name"
                  :src="author.image"
              ></v-img>
            </v-col>
            <v-col>
              <v-card-title class="text--primary">
                Authored By {{ author.name }}
              </v-card-title>
              <v-card-text>
                <div v-html="md(author.bio)"></div>
              </v-card-text>
              <v-chip
                  class="ma-2"
                  color="cyan"
                  link
                  text-color="white"
                  v-if="author.twitter && author.twitter.length>0"
                  small
                  target="_blank"
                  :href="author.twitter"
              >
                <v-icon left>
                  mdi-twitter
                </v-icon>
                Follow on Twitter
              </v-chip>
              <v-chip
                  class="ma-2"
                  color="cyan"
                  link
                  text-color="white"
                  v-if="author.linkedin && author.linkedin.length>0"
                  small
                  target="_blank"
                  :href="author.linkedin"
              >
                <v-icon left>
                  mdi-linkedin
                </v-icon>
                Follow on LinkedIn
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-col>
  </v-row>
</template>

<style>
.hljs {
  margin-bottom: 16px;
}
.pattern-image {
  max-width: 100%;
}
</style>
<script>

import VueCookies from 'vue-cookies';
import {marked} from 'marked';
import hljs from 'highlight.js';
import 'highlight.js/styles/github.css';


let patternsURL = window.location.origin + '/saasbuilder-patterns/';

function setupMarked() {
  marked.setOptions({
    "baseUrl": null,
    "breaks": true,
    "gfm": true,
    "extensions": null,
    "headerIds": true,
    "headerPrefix": "",
    "highlight": function (code, lang) {
      const language = hljs.getLanguage(lang) ? lang : 'plaintext';
      return hljs.highlight(code, {language}).value;
    },
    "langPrefix": "hljs language-", // highlight.js css expects a top-level 'hljs' class.
    "mangle": true,
    "pedantic": false,
    "sanitize": false,
    "sanitizer": null,
    "silent": false,
    "smartLists": false,
    "smartypants": false,
    "tokenizer": null,
    "walkTokens": null,
    "xhtml": true
  });

  // Override function
  const renderer = {
    heading(text, level) {
      return `
            <h${level} style="margin-top: 24px; margin-bottom: 16px;">
              ${text}
            </h${level}>`;
    }
  };

  marked.use({renderer});
}

setupMarked();

export default {
  data: () => ({
    patterns: [],
    dicPatterns: new Map(),
    patternsOpen: [],
    patternsLastOpen: [],
    patternAllOpened: false,
    categories: [],
    search: null,
    loading: false,
    treeActiveItem: null,
    selectedPattern: null,
    patternMarkDown: null,
    authors: null,
    active: [],
  }),
  watch: {
    search() {
      if (this.loading == false) {
        VueCookies.set('Search', this.search == null ? '' : this.search, "24h");
      }
    }
  },
  methods: {
    md: function (input) {
      if (input === undefined || input === null) {
        return "";
      }
      return marked.parse(input);
    },
    handleSearch(val) {
      // This section handles auto opening of tree leafs when there is a search and keeps a record of the current open leafs
      // if we clear the search the tree goes back to its original state
      if (val) {
        if (!this.patternAllOpened) {
          this.patternsLastOpen = this.patternsOpen;
          this.patternAllOpened = true;
          this.$refs.patternTree.updateAll(true);
        }
      } else {
        this.resetSearch();
      }
    },
    resetSearch() {
      this.$refs.patternTree.updateAll(false);
      this.patternAllOpened = false;
      this.patternsOpen = this.patternsLastOpen;
    },
    addPatternsToDictionary(patterns, parent) {
      patterns.forEach(pattern => {
        pattern.parent = parent;
        this.dicPatterns.set(pattern.id, pattern);
        if (pattern.children !== undefined && pattern.children !== null) {
          this.addPatternsToDictionary(pattern.children, pattern);
        }
      });
    },
    async getPatterns() {
      this.loading = true;
      let url = patternsURL + 'patterns.json';
      await fetch(url)
          .then(response => response.text())
          .then(text => {
                let jPatterns = JSON.parse(text);
                this.addPatternsToDictionary(jPatterns.patterns, null);
                this.patterns = jPatterns.patterns;
              }
          );
      this.loading = false;
    },
    resetFilters() {
      this.search = null;
      this.resetSearch();
    },
    loadUserSettings() {
      this.loading = true;

      let openJSON = VueCookies.get('PatternsOpen');
      if (openJSON !== undefined && openJSON !== null && openJSON.length > 0) {
        let openPatternIds = JSON.parse(openJSON);
        for (let x = 0; x < openPatternIds.length; x++) {
          this.patternsOpen.push(this.dicPatterns.get(openPatternIds[x]));
        }
      }
      let selectedPatternId = VueCookies.get('SelectedPatternID');
      if (selectedPatternId !== undefined && selectedPatternId !== null && selectedPatternId.length > 0) {
        this.active.push(this.dicPatterns.get(selectedPatternId));
      }

      let searchCheck = VueCookies.get('Search');
      if (searchCheck !== undefined && searchCheck !== null && searchCheck.length > 0 && searchCheck != 'null') {
        this.search = searchCheck;
        this.$refs.patternTree.updateAll(true);
      }

      this.loading = false;
    },
    async treeNodeOpen(items) {
      if (this.loading == false) {
        let patternsOpen = [];
        for (let x = 0; x < items.length; x++) {
          patternsOpen.push(items[x].id);
        }
        VueCookies.set('PatternsOpen', JSON.stringify(patternsOpen), "24h");
      }
    },
    async treeNodeActive(node) {
      let item = node !== undefined && node !== null && node.length > 0 ? node[0] : undefined;
      if (item !== undefined && item !== null && item.id !== null && item.path !== null && item.path.length > 0) {
        this.patternsOpen.push(item);
        this.selectedPattern = item;
        VueCookies.set('SelectedPatternID', this.selectedPattern.id, "24h");
        //grab the pattern document to show
        let rootURL = patternsURL + this.selectedPattern.path + "/";
        fetch(patternsURL + this.selectedPattern.path + "/pattern.md")
            .then(response => response.text())
            .then(text => this.patternMarkDown = text.replaceAll(/(\.\/.*?\.(?:jpg|jpeg|png|gif|svg|tiff|ico))/g, (match) => {

              return rootURL + match;
            }));


        fetch(patternsURL + this.selectedPattern.path + "/authors.json")
            .then(response => response.text())
            .then(text => {
              let authorJSON = text.replaceAll(/(\.\/.*?\.(?:jpg|jpeg|png|gif|svg|tiff|ico))/g, (match) => {
                return rootURL + match;
              });
              this.authors = JSON.parse(authorJSON).authors;
            });
      } else {
        this.selectedPattern = null;
        this.patternMarkDown = null;
        this.authors = null;
      }
    },
    getPatternFullPath(pattern) {
      if (pattern.parent !== null) {
        return this.getPatternFullPath(this.dicPatterns.get(pattern.parent.id)) + '\\' + pattern.name;
      }
      return pattern.name;
    },
    submitPatternNavigation() {
      window.open(process.env.VUE_APP_PATTERNS_SUBMIT_URL, '_blank');
    }
  },
  async created() {
    await this.getPatterns().then(() => {
      this.loadUserSettings();
    });
  }
}
</script>

